import { useQuery } from "@tanstack/react-query";
import customAxios from "utils/axios";

// const API_LOCATION = process.env.REACT_APP_API_LOCATION
const API_LOCATION = `${process.env.REACT_APP_API_SCHEDULE}/api/employee/location`;

const API_ALL_JOBPOSITION = `${process.env.REACT_APP_API_SCHEDULE}/api/employee/getJobPositions`;
const API_ALL_DEPARTMENT = `${process.env.REACT_APP_API_SCHEDULE}/api/employee/getDepartments`;

// API FOR ONLINE SERVER

// const serverOnlineEmployee = process.env.REACT_APP_API_EMPLOYEE;

const API_EMPLOYEE = `${process.env.REACT_APP_API_SCHEDULE}/api/employee`;

export const useGetAllEmployee = (props = {}) => {
  const { firstName, locationId, jobPosition, department, startDate, endDate } =
    props;

  const getEmployee = async () => {
    // Gunakan URLSearchParams untuk membangun query string
    const queryParams = new URLSearchParams();

    // Hanya tambahkan parameter jika nilainya tidak kosong
    if (firstName) queryParams.append("firstName", firstName);
    if (locationId) queryParams.append("locationID", locationId);
    if (jobPosition) queryParams.append("jobPosition", jobPosition);
    if (department) queryParams.append("departmentName", department);
    if (startDate) queryParams.append("startDate", startDate);
    if (endDate) queryParams.append("endDate", endDate);

    const queryString = queryParams.toString(); // Hasilkan string query

    // Bangun URL akhir, hanya tambahkan '?' jika queryString tidak kosong
    const url = `${API_EMPLOYEE}${queryString ? `?${queryString}` : ""}`;

    // Panggil API menggunakan axios
    const resp = await customAxios.get(url);

    return resp.data;
  };

  // Definisikan queryKey untuk cache management
  const queryKey = [
    "getEmployee",
    locationId,
    firstName,
    jobPosition,
    department,
  ];

  // Gunakan useQuery dari TanStack React Query
  return useQuery({
    queryKey: queryKey,
    queryFn: getEmployee,
    onError: (err) => {
      console.error("Something went wrong:", err);
    },
  });
};

export const useGetAllLocation = () => {
  const getLocation = async () => {
    const resp = await customAxios.get(`${API_LOCATION}`);
    return resp.data;
  };

  return useQuery({
    queryKey: ["getLocation"],
    queryFn: getLocation,
    onError: (err) => {
      console.log("Get Location Error ", err);
    },
  });
};

export const useGetAllJobPosition = () => {
  const jobPosition = async () => {
    const resp = await customAxios.get(`${API_ALL_JOBPOSITION}`);
    return resp.data;
  };

  return useQuery({
    queryKey: ["getAlljobPosition"],
    queryFn: jobPosition,
    onError: (err) => {
      console.log("GET ALL Job Position Something Wrong", err);
    },
  });
};

export const useGetAllDepartment = () => {
  const department = async () => {
    const resp = await customAxios.get(`${API_ALL_DEPARTMENT}`);
    return resp.data;
  };

  return useQuery({
    queryKey: ["getAllDepartment"],
    queryFn: department,
    onError: (err) => {
      console.log("GET ALL Job Position Something Wrong", err);
    },
  });
};
