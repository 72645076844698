import React from "react";
import {
  ColorPicker,
  InputTime,
  InputSelect,
  Button,
  InputDateRange,
  InputNumber,
} from "@bluesilodev/timhutcomponents";

// import { useSelector } from "react-redux";

import { generateTime } from "utils/common/generatedTime";

import InputWeekly from "./InputWeekly";

// import InputDateRange from "coba/Input/InputDateRange";
import { useGetAllLocation } from "services/employeeApi";

function FormAssignShift({
  nameButton,
  iconButton,
  shiftNameAssign,
  setValueShiftName,
  selectEmployees,
  selectJobs,
  values,
  handleSubmit,
  setFieldValue,
  handleBlur,
  errors,
  handleChange,
  touched,
  isActiveStatus,
  handleFindReplacement,
  statusReplacement,
  isReplacementStatus,
}) {
  //======== SHIFT TIME
  const resulDif = generateTime(values.startTime, values.endTime);

  //========= BREAK TIME
  const resultBreak = generateTime(values.startBreakTime, values.endBreakTime);

  const handleCheckboxChange = (event, checkedValue) => {
    if (values.repeatBy.repeatType === "Weekly") {
      const { checked } = event.target;
      let currentValues = Array.isArray(values.repeatBy.repeatValue)
        ? values.repeatBy.repeatValue
        : [];

      if (checked) {
        if (!currentValues.includes(checkedValue)) {
          currentValues = [...currentValues, checkedValue];
        }
      } else {
        currentValues = currentValues.filter((val) => val !== checkedValue);
      }

      setFieldValue("repeatBy.repeatValue", currentValues);
    }
  };

  // GET ALL LOCATION
  const { data: locationData, isLoading: loadingLocation } =
    useGetAllLocation();

  if (loadingLocation) {
    return <div>Loading...</div>;
  }

  // SELECT LOCATION
  const selectLocation =
    locationData &&
    locationData.data.length > 0 &&
    locationData?.data?.map((item) => {
      return {
        label: item.locationName,
        value: item.uId,
      };
    });

  return (
    <form onSubmit={handleSubmit} autoComplete="off" className="w-full">
      <div className="flex gap-3  w-full">
        <div className="w-[83%]">
          <InputSelect
            id="shiftName"
            title={"Shift Name"}
            options={shiftNameAssign ? shiftNameAssign : []}
            value={values.shiftName}
            // onChange={handleChange}
            onChange={(e) => {
              const { value } = e.target;
              setFieldValue("shiftName", value);
              if (value) {
                setFieldValue("shiftName", value);

                setValueShiftName(value);
              }
            }}
            disabled={isActiveStatus && true}
            onBlur={handleBlur}
            error={errors.shiftName && touched.shiftName && errors.shiftName}
          />
        </div>
        <div className="w-[16%]">
          <ColorPicker
            name="color"
            value={values.color}
            setFieldValue={setFieldValue}
            onChange={handleChange}
            rightPosition={"right-[2px]"}
            disabled={true}
          />
        </div>
      </div>

      <div className="flex gap-3  w-full mt-3 flex-col">
        <h1 className="font-semibold ">Time</h1>
        <div className="flex gap-3 w-full justify-between">
          <div className="w-full">
            <InputTime
              label={"Start Time"}
              name={"startTime"}
              value={values.startTime}
              setFieldValue={setFieldValue}
              onBlur={handleBlur}
              onChange={handleChange}
              disable={true}
            />
          </div>
          <div className="w-full">
            <InputTime
              label={"End Time"}
              name={"endTime"}
              value={values.endTime}
              setFieldValue={setFieldValue}
              onBlur={handleBlur}
              onChange={handleChange}
              disable={true}
            />
          </div>
          <div className="w-[250px]">
            <p>Shift Time</p>
            <p className="font-semibold">
              {resulDif.hours} Hours {resulDif.minutes} minute
            </p>
          </div>
        </div>

        <div className="flex gap-3 w-full justify-between">
          <div className="w-full">
            <InputTime
              label={"Start Break Time"}
              name={"startBreakTime"}
              value={values.startBreakTime}
              setFieldValue={setFieldValue}
              disable={true}
              onBlur={handleBlur}
              onChange={handleChange}
            />
          </div>
          <div className="w-full">
            <InputTime
              label={"End Break Time"}
              name={"endBreakTime"}
              value={values.endBreakTime}
              disable={true}
              setFieldValue={setFieldValue}
              onBlur={handleBlur}
              onChange={handleChange}
            />
          </div>
          <div className="w-[250px]">
            <p>Break Time</p>
            <p className="font-semibold">
              {resultBreak.hours} Hours {resulDif.minutes} minute
            </p>
          </div>
        </div>
      </div>

      {/* THIS IS REPEATS */}
      <div className="mt-3 flex flex-col gap-3 ">
        <h1 className="font-semibold">Repeats</h1>
        <div className="flex gap-3">
          <InputDateRange
            label={"Date Range"}
            name={"repeatDate"}
            value={values.repeatDate}
            setFieldValue={setFieldValue}
            disabled={true}
            onChange={handleChange}
            onBlur={handleBlur}
            required={true}
            error={errors.repeatDate && touched.repeatDate && errors.repeatDate}
          />

          <InputSelect
            id="repeatBy.repeatType"
            title={"Repeats"}
            value={values.repeatBy.repeatType}
            disabled={true}
            options={[
              { label: "Daily", value: "Daily" },
              { label: "Weekly", value: "Weekly" },
              { label: "Monthly", value: "Monthly" },
            ]}
            onChange={(e) => {
              const selectedValue = e.target.value;
              setFieldValue(`repeatBy.repeatType`, selectedValue);

              // Reset inputRepeatValue based on the new repeatType
              if (selectedValue !== "Weekly") {
                setFieldValue("repeatBy.repeatValue", 0);
              } else {
                setFieldValue("repeatBy.repeatValue", []);
              }
            }}
            onBlur={handleBlur}
          />
        </div>
        {values.repeatBy.repeatType === "Weekly" && (
          <InputWeekly
            values={values}
            errors={errors}
            touched={touched}
            handleCheckboxChange={handleCheckboxChange}
            disabled={true}
          />
        )}

        {values.repeatBy.repeatType === "Daily" && (
          <div className="w-[49%] mt-5">
            <InputNumber
              label={"Every"}
              unit={"Day(s)"}
              name={"repeatBy.repeatValue"}
              disable={true}
              onBlur={handleBlur}
              value={values.repeatBy.repeatValue}
              error={
                errors.repeatBy &&
                errors.repeatBy.repeatValue &&
                touched.repeatBy &&
                touched.repeatBy.repeatValue &&
                errors.repeatBy.repeatValue
              }
              setFieldValue={setFieldValue}
            />
          </div>
        )}

        {values.repeatBy.repeatType === "Monthly" && (
          <div className="w-[49%] mt-5">
            <InputNumber
              label={"Every"}
              unit={"Month(s)"}
              name={"repeatBy.repeatValue"}
              value={values.repeatBy.repeatValue}
              disable={true}
              onBlur={handleBlur}
              error={
                errors.repeatBy &&
                errors.repeatBy.repeatValue &&
                touched.repeatBy &&
                touched.repeatBy.repeatValue &&
                errors.repeatBy.repeatValue
              }
              setFieldValue={setFieldValue}
            />
          </div>
        )}
      </div>

      <div className="mt-3 flex flex-col gap-3">
        <h1 className="font-semibold">Assign To</h1>
        <div className="flex flex-col gap-3">
          <div className="w-[39%]">
            <InputSelect
              title={"Locations"}
              // value={"Cafe Halim"}
              value={values.locationId}
              onChange={handleChange}
              options={selectLocation}
              name="repeatType"
              disabled={true}
            />
          </div>
          <div className="flex gap-3">
            <InputSelect
              id="jobPosition"
              onChange={handleChange}
              value={values.jobPosition}
              onBlur={handleBlur}
              error={
                errors.jobPosition && touched.jobPosition && errors.jobPosition
              }
              disabled={true}
              title={"Job Position"}
              options={selectJobs ? selectJobs : []}
            />

            <InputSelect
              name="employee"
              onChange={handleChange}
              value={values.employee}
              onBlur={handleBlur}
              error={errors.employee && touched.employee && errors.employee}
              title={"Employee"}
              options={selectEmployees ? selectEmployees : []}
              disabled={isActiveStatus && true}
            />

            {isReplacementStatus && (
              <Button
                type={"button"}
                style={"solid"}
                onClick={handleFindReplacement}
                className={"w-[50%]"}
                label={
                  <div className="flex gap-2 items-center">
                    Request Replacement
                  </div>
                }
              />
            )}
          </div>
        </div>
      </div>

      <div className="w-full mt-5 ">
        {!isActiveStatus && (
          <Button
            type={"submit"}
            style={"solid"}
            className={"w-full"}
            label={
              <div className="flex gap-2 items-center">
                <div>{iconButton}</div>
                <h1>{nameButton}</h1>
              </div>
            }
          />
        )}
      </div>
    </form>
  );
}

export default FormAssignShift;
