import React from "react";
import { Accordion, Button } from "@bluesilodev/timhutcomponents";
import { useSelector } from "react-redux";
import { useQueryClient } from "@tanstack/react-query";

import { useGetAllLocation } from "services/employeeApi";
import { usePublishAssignShift } from "services/assignShiftService";
import { useGetAssignShift } from "services/assignShiftService";
import { formatDateSlash } from "utils/common/generateDateRange";
import { PaperUpload } from "assets/icons";
import { formatDate } from "utils/common/generateDateRange";
import { alertSuccess, alertError } from "services/alert";

function PublishDetailData({ values, setConfirmationPublish }) {
  const { locationState, dataDate } = useSelector(
    (state) => state.employeeView
  );
  const queryClient = useQueryClient();

  const startDate = dataDate.length > 0 && dataDate[0];
  const endDate = dataDate.length > 0 && dataDate[dataDate.length - 1];

  const fromDate = dataDate.length > 0 && formatDateSlash(startDate);
  const toDate = dataDate.length > 0 && formatDateSlash(endDate);

  //   GET ALL LOCATION
  const { data: locationData, isLoading: loadingLocation } =
    useGetAllLocation();

  const { data: allAssignShift, isLoading: loadingAllAssign } =
    useGetAssignShift({
      fromDate: startDate,
      toDate: endDate,
    });

  const { mutate: publishAssignShift, isLoading: loadingPublish } =
    usePublishAssignShift({
      onSuccess: () => {
        setConfirmationPublish(false);
        queryClient.invalidateQueries("getDashboard");
        alertSuccess("Publish Data Success");
      },
      onError: (err) => {
        alertError("Somethin Wrong !");
        console.log("Something Wrong !", err);
      },
    });

  if (
    loadingLocation ||
    loadingAllAssign ||
    loadingPublish
    // loadingDashboard
  ) {
    return <div>Loading...</div>;
  }

  //   FILTER BY LOCATION ID
  const filterLocation =
    locationData.data.length > 0 &&
    locationData.data.filter((item) => item.uId === locationState);

  const locationId =
    filterLocation && filterLocation.length > 0 && filterLocation[0]?.uId;

  const locationName =
    filterLocation &&
    filterLocation.length > 0 &&
    filterLocation[0].locationName;

  // FILTER ALL ASSIGNSHIFT
  const filterAllAssignShift =
    allAssignShift &&
    allAssignShift.data.length > 0 &&
    allAssignShift.data.filter((item) => {
      const locationAssignId =
        item?.shiftDetails && item?.shiftDetails?.locationId;

      return locationId
        ? item.isActive === false && locationId === locationAssignId
        : item.isActive === false;
    });

  const groupData = (data) => {
    // Step 1: Group by shiftData.uId
    const groupedByShift = data.reduce((acc, item) => {
      if (item.shiftDetails) {
        const { uId, shiftName, startTime, endTime } = item.shiftDetails;
        if (!acc[uId]) {
          acc[uId] = {
            shiftUId: uId,
            shiftName,
            startTime,
            endTime,
            users: [],
          };
        }

        // Step 2: Group by userId
        const { userId } = item;

        const existingUser = acc[uId].users.find(
          (user) => user.userId === userId
        );

        const newDate = new Date(item.date);

        if (!existingUser) {
          acc[uId].users.push({
            userId: item.userId,
            jobPosition: item.jobPosition,
            employeeData: item.employeeData,
            dates: [newDate], // Mulai dengan menambahkan date pertama
          });
        } else {
          // Step 3: Collect the dates if userId already exists
          existingUser.dates.push(newDate);
        }
      }
      return acc;
    }, {});

    const result = Object.values(groupedByShift);

    return result;
  };

  const groupAllData =
    filterAllAssignShift &&
    filterAllAssignShift.length > 0 &&
    groupData(filterAllAssignShift);

  const handlePublishAssignShift = () => {
    const date = {
      fromDate: values.date[0],
      toDate: values.date[values.date.length - 1],
    };

    const withLocationId = {
      fromDate: formatDate(values.date[0]),
      toDate: formatDate(values.date[values.date.length - 1]),
      locationId: locationState,
    };

    if (locationState) {
      publishAssignShift(withLocationId);
    } else {
      publishAssignShift(date);
    }
  };

  return (
    <div>
      <h1>Publish Shift for {locationName ? locationName : "All Location"}</h1>
      <p className="font-semibold">
        {fromDate} - {toDate}
      </p>

      <div className="flex flex-col gap-4">
        {groupAllData.length > 0 &&
          groupAllData.map((item, index) => {
            return (
              <Accordion
                key={index}
                title={
                  <p className="text-[16px]">
                    {item.shiftName} ( {item.startTime} - {item.endTime} )
                  </p>
                }
                children={
                  <div className="flex flex-col gap-3">
                    {item.users.length > 0 &&
                      item.users.map((user, idx) => {
                        const employee = user.employeeData;

                        const month =
                          user.dates.length > 0 &&
                          user.dates[0].toLocaleString("default", {
                            month: "long",
                          }); // Ambil nama bulan dari tanggal pertama
                        const formattedDates =
                          user.dates.length > 0 &&
                          user.dates
                            .map((date) =>
                              String(date.getDate()).padStart(2, "0")
                            )
                            .join(", ");

                        return (
                          <div key={idx} className="">
                            <div>
                              <span className="font-semibold">
                                {user.jobPosition}
                              </span>{" "}
                              assign to{" "}
                              <span className="font-semibold">
                                {employee.firstName} {employee.lastName}
                              </span>{" "}
                              on{" "}
                              <span>
                                {formattedDates} {month}{" "}
                              </span>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                }
              />
            );
          })}
      </div>

      <div className="mt-8 w-full ">
        <Button
          onClick={handlePublishAssignShift}
          label={
            <div
              className={`flex items-center gap-3 ${
                !filterAllAssignShift || filterAllAssignShift.length === 0
                  ? "text-black"
                  : "text-white"
              }`}
            >
              <PaperUpload
                className={`${
                  !filterAllAssignShift || filterAllAssignShift.length === 0
                    ? "text-black"
                    : "text-white"
                }`}
              />
              <h1>Publish Shift</h1>
            </div>
          }
          disabled={!filterAllAssignShift || filterAllAssignShift.length === 0}
          style={"secondary"}
          className={"w-full"}
        />
      </div>
    </div>
  );
}

export default PublishDetailData;
